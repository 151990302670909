import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ProductResponse } from "@web/sherlock";
import { IRow, Table } from "@web/ui";

type Props = {
  catalogId: string;
  productsList?: ProductResponse[];
  isLoading?: boolean;
};
const ProductsListTable: FC<Props> = ({ catalogId, isLoading, productsList }) => {
  const navigate = useNavigate();
  const handleRowClick = useCallback(
    (id?: string) => {
      navigate(`/catalogs/${catalogId}/products/${id}`);
    },
    [catalogId, navigate]
  );

  const rows = useMemo(() => {
    return productsList?.map((item: ProductResponse): IRow => {
      return {
        onClick: () => handleRowClick(item?.productId),
        data: [
          {
            type: "title",
            data: String(item.productId),
          },
          {
            type: "title",
            data: item.name,
          },
        ],
      };
    });
  }, [productsList, handleRowClick]);

  return (
    <Table
      testId="productsListTable"
      headings={[
        {
          text: "Supplier Article Code",
        },
        {
          text: "Short description",
        },
      ]}
      isLoading={isLoading}
      rows={rows}
      emptyState={{
        title: "We looked everywhere..",
        subtitle: "There aren’t any products.",
      }}
    />
  );
};
export default ProductsListTable;
