import { useEffect, useState } from "react";

import { IntersectionMonitor } from "@web/common";
import { ProductResponse } from "@web/sherlock";
import { Heading, Label, Loading, OptionType, Select } from "@web/ui";
import { Catalog } from "@web/warehouse";

import { Layout } from "src/components/Layout";

import { useCatalogsQuery, useInfiniteProductsListQuery } from "../CatalogsPage/api";
import ProductsListTable from "./components/ProductsListTable";

export const ProductsPage = () => {
  const [productsList, setProductsList] = useState<ProductResponse[]>();
  const [shouldFetchProducts, setShouldFetchProducts] = useState<boolean>(false);
  const [showHint, setShowHint] = useState<boolean>(true);
  const [selectedCatalog, setSelectedCatalog] = useState({
    value: "select-catalog",
    label: "Select catalog",
    srn: "",
  });

  const convertCatalogs = (data: Catalog[]) => {
    return data
      .filter((catalog) => catalog.catalogId && catalog.name)
      .map((catalog) => ({
        value: catalog.catalogId,
        label: catalog.name,
        srn: catalog.srn,
      }));
  };

  const { data: catalogsList, isPending: isCatalogsListPending } = useCatalogsQuery();

  const {
    data: productsListData,
    isPending: isProductsListPending,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage: isFetchingMore,
  } = useInfiniteProductsListQuery(selectedCatalog.srn, 20, "ALL", "", "", "", {
    enabled: shouldFetchProducts,
  });

  const handleFetchProducts = () => {
    setShouldFetchProducts(false);
    setShowHint(false);
    setTimeout(() => {
      setShouldFetchProducts(true);
    }, 100);
  };

  const onSelectedCatalogChange = (value: OptionType<string> & { srn?: string }) => {
    setSelectedCatalog({
      value: value.value,
      label: value.label,
      srn: value.srn || "",
    });
    setShouldFetchProducts(false);

    if (value.value !== "select-catalog") {
      handleFetchProducts();
    }
  };

  useEffect(() => {
    if (productsListData?.pages && !isProductsListPending) {
      const allProducts = productsListData.pages.flatMap((page) => page.products);
      setProductsList(allProducts as ProductResponse[]);
    }
  }, [isProductsListPending, productsListData?.pages, setProductsList, showHint]);

  return (
    <Layout>
      <div className="w-[348px] mb-5">
        <Label size="200" color="text-textIcon-blackPrimary">
          Catalog
        </Label>
        {!isCatalogsListPending ? (
          <Select
            placeholder="Category"
            value={selectedCatalog}
            options={convertCatalogs(catalogsList as Catalog[]) as OptionType[]}
            onChange={onSelectedCatalogChange}
            className="z-50"
            dropdownHPosition="left-auto right-0"
          />
        ) : (
          <Loading />
        )}
      </div>
      <div className="z-0">
        {!isProductsListPending && shouldFetchProducts && (
          <ProductsListTable catalogId={selectedCatalog.value} productsList={productsList} />
        )}
        {isProductsListPending && shouldFetchProducts && <Loading />}
        {showHint && (
          <div
            className="flex center items-center text-center w-full justify-center mt-20"
            data-testid="catalogsPage_hint"
          >
            <Heading size="300" color="text-textIcon-blackSecondary">
              Please select catalog to view your products.
            </Heading>
          </div>
        )}
      </div>
      {hasNextPage && shouldFetchProducts && (
        <IntersectionMonitor
          onEnter={() => {
            fetchNextPage();
          }}
        >
          <div className="h-10">{isFetchingMore && <Loading />}</div>
        </IntersectionMonitor>
      )}
    </Layout>
  );
};
