export const convertNetContent = (value: string) => {
  const match = value.match(/^(\d+(?:\.\d+)?)(\s*\w+)?$/);
  if (!match) {
    return value;
  }

  const numberPart = parseFloat(match[1]).toFixed(2);
  const unitPart = match[2] ? match[2].trim() : "";

  return unitPart ? `${numberPart} ${unitPart}` : numberPart;
};
