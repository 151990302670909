export const splitQuantityAndUnit = (value?: string): { quantity: string; unit: string } => {
  if (!value) {
    return { quantity: "-", unit: "-" };
  }

  const parts = value.trim().split(" ");
  if (parts.length === 1) {
    return {
      quantity: parts[0],
      unit: "-",
    };
  }

  return {
    quantity: parts[0],
    unit: parts[1],
  };
};
